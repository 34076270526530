import "./scss/style.scss";
import React from "react";
import {
  CssBaseline,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material";
import GlobalTheme from "./components/themes/GlobalTheme";
import "./index.css";
import { AppProvider } from "./context";
import { Spinner } from "react-bootstrap";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const AppLayout = React.lazy(() => import("./containers/AppLayout"));

const App: React.FC = () => {
  return (
    <AppProvider>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={GlobalTheme}>
          <CssBaseline />
          <React.Suspense
            fallback={<Spinner animation={"grow"} variant={"secondary"} />}
          >
            <AppLayout />
          </React.Suspense>
        </ThemeProvider>
      </StyledEngineProvider>
    </AppProvider>
  );
};

export default App;
