import { createTheme, responsiveFontSizes } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface Theme {
    custom: {
      panelHeader: string;
      mainHeader: string;
      headerLogoUrl: string;
      sidebar: string;
      lightPurple: string;
      lightPink: string;
      green: string;
      yellow: string;
      blue: string;
      pink: string;
      orange: string;
      lightGreen: string;
    };
  }
  // allow configuration using `createTheme`
  interface DeprecatedThemeOptions {
    custom?: {
      panelHeader?: string;
      mainHeader?: string;
      headerLogoUrl?: string;
      sidebar?: string;
      lightPurple?: string;
      lightPink?: string;
      green?: string;
      yellow?: string;
      blue?: string;
      pink?: string;
      orange?: string;
      lightGreen?: string;
    };
  }
}

const theme = createTheme({
  typography: {
    fontFamily: "Lato",
    h1: {
      fontFamily: "Phoreus Cherokee",
    },
    h2: {
      fontFamily: "Phoreus Cherokee",
    },
    h3: {
      fontFamily: "Phoreus Cherokee",
    },
    h4: {
      fontFamily: "Phoreus Cherokee",
    },
    h5: {
      fontFamily: "Phoreus Cherokee",
    },
    h6: {
      fontFamily: "Phoreus Cherokee",
    },
  },
  palette: {
    mode: "light",
    primary: {
      main: "#331B3F",
    },
    secondary: {
      main: "#e3d5e5",
      light: "#d6cdd6",
    },
    success: {
      main: "#3CBD89",
    },
  },
  components: {
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: "32px",
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        "@global": {
          "*::-webkit-scrollbar": {
            display: "none",
          },
          "ms-overflow-style": {
            display: "none",
          },
          "*": {
            scrollbarWidth: "none",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          },
          "*::-webkit-scrollbar-track": {
            "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
          },
          "*::-webkit-scrollbar-thumb": {
            backgroundColor: "rgba(0,0,0,.1)",
            outline: "1px solid slategrey",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "0px",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        subtitle2: {
          fontSize: "0.7rem",
          "@media (min-width:1000px)": {
            fontSize: "0.8rem",
          },
          "@media (min-width:2000px)": {
            fontSize: "1rem",
          },
          "@media (min-width:3000px)": {
            fontSize: "1.2rem",
          },
        },
        subtitle1: {
          "@media (min-width:1000px)": {
            fontSize: "1rem",
          },
          "@media (min-width:2000px)": {
            fontSize: "1.2rem",
          },
          "@media (min-width:3000px)": {
            fontSize: "1.5rem",
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          padding: "15px",
        },
      },
    },
  },
  custom: {
    panelHeader: "linear-gradient(60deg, #4b75b8, #495abf)",
    mainHeader: "#FAFAFA",
    sidebar: " #fff",
    lightPurple: "#8080E73D",
    lightPink: "#D877AB85",
    green: "#3CBD89",
    yellow: "#FFCB4E",
    blue: "#3DB8E0",
    pink: "#D877AB",
    orange: "#FF6D4C",
    lightGreen: "#3CBD896E",
  },
});

const GlobalTheme = responsiveFontSizes(theme);

export default GlobalTheme;
