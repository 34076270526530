import React, { createContext, useReducer } from "react";
import Reducer from "./reducer";
import { IToken } from "./types/token";

type InitialStateType = {
  isUserLoggedIn: boolean;
  role: string;
  msalInstance: any;
  user: IToken;
};

const initialState = {
  isUserLoggedIn: true,
  role: "",
  msalInstance: null,
  user: {} as IToken,
};

const AppContext = createContext<{
  state: InitialStateType;
  dispatch: React.Dispatch<any>;
}>({
  state: initialState,
  dispatch: () => null,
});

const AppProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
