const Reducer = (state: any, action: any) => {
  switch (action.type) {
    case "SET_ERROR":
      return {
        ...state,
        error: action.payload,
      };
    case "SET_ISUSERLOGGEDIN":
      return {
        ...state,
        isUserLoggedIn: action.payload,
      };
    case "SET_ROLE":
      return {
        ...state,
        role: action.payload,
      };
    case "SET_MSALINSTANCE":
      return {
        ...state,
        msalInstance: action.payload,
      };
    case "SET_USER":
      return {
        ...state,
        user: action.payload,
      };
    default:
      return state;
  }
};

export default Reducer;
